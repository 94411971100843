import { Link } from "react-router-dom";
import logo from "../../../assets/logodark.png";
import Button from "../../Buttons";
import Textfield from "../../Textfield";
import styles from "./coursesCard.module.css";

const CoursesCard = ({
  imgSrc,
  courseName,
  description,
  keyPoints,
  current,
  buttonText,
  link
}) => {
  return (
    <div
      className={`${styles.coursesCard} ${
        current ? styles.currentCard : styles.card
      }`}
    >
      <img src={imgSrc} className={styles.courseImg} />
      <div className={styles.detailsDiv}>
        <div>
          <img src={logo} alt="logo" className={styles.logo} />
          <Textfield
            type="h1"
            style={{ marginBlock: "10px", color: "#1D4C92" }}
          >
            {courseName}
          </Textfield>
          <Textfield type="h5" style={{ marginBottom: "20px" }}>
            {description}
          </Textfield>
          <hr className="hrLine" />
          <Textfield type="h3" style={{ marginBlock: "10px" }}>
            KEY POINTS
          </Textfield>
          <ul>
            {keyPoints.map((el) => {
              return (
                <li>
                  {
                    <Textfield
                      type="h5"
                      style={{
                        marginBlock: "10px",
                        color: "#1D4C92",
                        fontWeight: "bold",
                      }}
                    >
                      {el}
                    </Textfield>
                  }
                </li>
              );
            })}
          </ul>
          <hr className="hrLine" />
          <Textfield
            type="h5"
            style={{ marginBlock: "10px", fontWeight: "bold" }}
          >
            Recommended Professional Certificate(s)
          </Textfield>
        </div>
        <div className={styles.btnDiv}>
          <Link to={link}>
            <Button type="filled" style={{ borderRadius: "14px" }}>
              {buttonText}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CoursesCard;
