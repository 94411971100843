import { useState, useEffect } from "react";
import Textfield from "../Textfield";
import heroImg from "../../assets/heroImg.svg";
import AboutusMainImage from "../../assets/about/ABOUT_FIRST.jpg";
import UserJournyImage from "../../assets/about/ABOUT_SECOND_IMAGE.png";
import styles from "./about.module.css";
import CardWrapper from "../CardWrapper";
import Button from "../Buttons";
import ServicesBox from "./Services";
import CoursesCard from "./CoursesCard";
import CommonCarousel from "../CarouselContainer/index";
import DentistCard from "./DentistCard/Index";
import FaqAccordian from "./FaqAccordian";
import FromTheBlogsWrapper from "../FromTheBlogs";
//images
import brush from "../../assets/brush.svg";
import braces from "../../assets/braces.svg";
import teeth from "../../assets/teeth.svg";
import file from "../../assets/file.svg";
import courseImg from "../../assets/course.svg";
import dentistImg from "../../assets/DenistImg.svg";
import faqBtn from "../../assets/faqBtn.svg";
import blogImg from "../../assets/blogImg.svg";
import { HashLink as Link } from "react-router-hash-link";
import AboutSectionOne from "./AboutSectionOne";

// import IntraOral from "../../assets/course/Clinic Course/oral.png"
import IntraOral from "../../assets/about/Intra oral scanning_.png";

import Xray from "../../assets/course/Clinic Course/X_ray.png";
import MillingCourse from "../../assets/course/Clinic Course/Milling.png";

const coursesData = [
  {
    imgSrc: IntraOral,
    courseName: "Intra Oral Course",
    description:
      "Master the art of intraoral scanning with comprehensive training on IOS technology, digital workflows, and troubleshooting techniques",
    keyPoints: [
      "Setting up of IOS Scanner ",
      "Retraction & Isolation Techniques",
      "Accuracy and Quality Control",
    ],
    buttonText: "View Details",
    link: "/courses?course=dental",
  },
  {
    imgSrc: Xray,
    courseName: "X-Ray",
    description:
      "Master the fundamentals of 3D printing, optimize machine efficiency, and create intricate components with this comprehensive course. ",
    keyPoints: [
      "Introduction to 3D Printing ",
      "Nesting ",
      "Machine Components and Operations ",
    ],
    buttonText: "Coming Soon",
    link: "#",
  },
  {
    imgSrc: MillingCourse,
    courseName: "Milling Course ",
    description:
      "Master essential skills in software training, file management, CAM software, and milling techniques for a successful career in digital dentistry. ",
    keyPoints: ["Software Training ", "File Management ", "CAM Software "],
    buttonText: "Coming Soon",
    link: "#",
  },
  // Add more courses as needed
];

const About = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleAfterChange = (nextSlide, _ref2) => {
    const { currentSlide } = _ref2;
    setCurrentSlide(currentSlide - 1);
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <main>
      {/* <section className={styles.sec}>
        <Textfield
          type="h3"
          style={{ marginBlock: "10px", marginInline: "20px" }}
        >
          Our team of experts help budding
          <br />
          dentists to master digital dentistry skills.
        </Textfield>
        <Textfield
          colorType="blue"
          type="h5"
          style={{ fontWeight: "500", marginInline: "20px" }}
        >
          The ultimate experiential learning company to step into the world of
          digital dentistry.
        </Textfield>
        <div className={styles.heroDiv}>
          <img src={AboutusMainImage} className={styles.heroImg} alt="" />
          <CardWrapper
            additionalStyles={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Textfield
                type="h5"
                style={{
                  fontWeight: "600",
                  marginBlock: "10px",
                  letterSpacing: "3px",
                  color: "#FFFFFF99",
                }}
              >
                Why Choose Us?
              </Textfield>
              <Textfield
                colorType="white"
                type="h3"
                style={{ marginBlock: "20px" }}
              >
                Master digital dentistry with our immersive AR/VR training.
                Learn at your own pace, anytime, anywhere, with flexible,
                personalized modules taught by industry leaders. Our scalable
                online platform ensures top-quality education that fits your
                schedule and budget.
              </Textfield>
            </div>
            <div className={styles.cardFooter}>
              <Textfield colorType="white" type="h3">
                Call Us:
              </Textfield>
              <Button type="oulinedBlurBg" style={{ borderRadius: "14px" }}>
                +91 -8880003344
              </Button>
            </div>
          </CardWrapper>
        </div>
      </section> */}
      <section className={styles.sec}>
        <AboutSectionOne />
      </section>
      <section className={styles.sec2}>
        <Textfield
          colorType="blue"
          type="h1"
          style={{
            margin: "auto",
            width: "70%",
            textAlign: "center",
            color: "#1853AB",
          }}
        >
          Our mission is to be the premier online platform for VR-powered
          experiential learning, empowering dental professionals to master
          digital dentistry and master their skills.
        </Textfield>
      </section>
      <section className={styles.sec3}>
        <div>
          {/* <div>
            <Textfield
              colorType="black"
              type="h6"
              style={{ fontWeight: "500", margin: "20px" }}
            >
             
            </Textfield>
          </div> */}
          <div className={styles.flexCss}>
            <Textfield
              type="h1"
              style={{ marginBlock: "10px", marginInline: "20px" }}
            >
              User Journey
            </Textfield>
            <Textfield
              type="h5"
              style={{
                fontWeight: "500",
                marginInline: "20px",
                color: "#013A65B5",
                textAlign: "center",
              }}
            >
              We have many VR-enabled teaching methods, ease of learning scope
              and video assessments that <br />
              will help dental professionals get ready for the real world.
            </Textfield>
            <Link smooth to="#contactus">
              <Button
                type="filledLight"
                style={{ margin: "30px 0px", padding: "12px 30px" }}
              >
                Book a demo
              </Button>
            </Link>
            <img src={UserJournyImage} className={styles.demoImg} />
            <div className={styles.servicesContainer}>
              <ServicesBox
                imgSrc={brush}
                heading={"L-3 Learning Modules"}
                content="Lots of interactions, storyline and gamification. "
              />
              <ServicesBox
                imgSrc={braces}
                heading={"High Practical Learning"}
                content="With the help of VR, exposure to practical learning. "
              />
              <ServicesBox
                imgSrc={teeth}
                heading={"Scoring Versions"}
                content="With the Test Me Version, gain good scores"
              />
              <ServicesBox
                imgSrc={file}
                heading={"Video Assessment "}
                content="With a video assessment in the end, pass with flying colors"
              />
            </div>
          </div>
        </div>
        {/* <div className={styles.levelUp}>
          <img src={heroImg} />
          <CardWrapper
            additionalStyles={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Textfield
                type="h6"
                style={{
                  fontWeight: "500",
                  marginBlock: "10px",
                  letterSpacing: "3px",
                  color: "#FFFFFF99",
                }}
              >
                PRIORITISE YOUR DENTAL HEALTH
              </Textfield>
              <Textfield
                colorType="white"
                type="h2"
                style={{ marginBlock: "20px", textAlign: "left" }}
              >
                Always Levelup Your current
                <br />
                skills using <br />
                modern tech
                <br />
                solution
              </Textfield>
              <Textfield
                type="h6"
                style={{
                  fontWeight: "500",
                  marginBlock: "10px",
                  color: "#FFFFFF99",
                }}
              >
                The ultimate dental health experience for your whole family in
                Portland, Oregon The ultimate dental health experience for your
                whole family in Portland, Oregon
              </Textfield>
            </div>
            <div
              className={styles.cardFooter}
              style={{ justifyContent: "start", gap: "20px" }}
            >
              <Button type="filled" style={{ borderRadius: "14px" }}>
                View Details
              </Button>
              <Button type="transparent" style={{ borderRadius: "14px" }}>
                +91 -8880003344
              </Button>
            </div>
          </CardWrapper>
        </div> */}
      </section>
      <section className={styles.sec4}>
        <Textfield
          type="h2"
          colorType="blue"
          style={{
            marginTop: "10px",
            marginBottom: "50px",
            marginInline: "20px",
          }}
        >
          Become a Professional with Our Digital Dentistry Courses
        </Textfield>
        <CommonCarousel handleAfterChange={handleAfterChange}>
          {coursesData.map((course, i) => (
            <CoursesCard
              key={i} // Use a unique key based on the index or course data
              imgSrc={course.imgSrc}
              courseName={course.courseName}
              description={course.description}
              keyPoints={course.keyPoints}
              buttonText={course.buttonText}
              current={currentSlide === i + 1}
              link={course.link}
            />
          ))}
        </CommonCarousel>
      </section>
      <section className={styles.sec5}>
        <Textfield
          type="h6"
          colorType="white"
          style={{ marginBlock: "20px", marginInline: "20px" }}
        ></Textfield>
        <Textfield
          type="h1"
          colorType="white"
          style={{ marginBlock: "20px", marginInline: "20px" }}
        >
          Minds Behind the Modules
        </Textfield>
        <Textfield
          type="h5"
          colorType="white"
          style={{ margin: "20px 0px 40px 0px" }}
        >
          Our educators and mentors have years of experience in the dental
          industry.
        </Textfield>
        <div className={styles.dentistCardContainer}>
          <DentistCard
            imgSrc={dentistImg}
            name="Harmeet Bindra "
            designation="Mech Engineer (Mentor)"
            description="Harmeet Bindra, a seasoned engineer and tech entrepreneur leverages emerging technologies and India's expertise to revolutionize the dental restoration industry with high-quality, affordable solutions. "
            additinalStyles={{
              transform: isMobile ? "scale(1,1)" : "scale(0.8,0.8)",
            }}
          />
          <DentistCard
            imgSrc={dentistImg}
            name="Dr. Manish "
            designation="BDS (CEO & Founder) "
            description="Dr. Manish is an experienced dental professional specializing in CAD/CAM technology with a proven track record of transforming graduates into world-class digital dental designers. "
            additinalStyles={{ transform: "scale(1,1)" }}
          />
          <DentistCard
            imgSrc={dentistImg}
            name="Bill Grill "
            designation="Educator  "
            description=""
            additinalStyles={{
              transform: isMobile ? "scale(1,1)" : "scale(0.8,0.8)",
            }}
          />
        </div>
      </section>
      <section className={styles.faqSec}>
        <div className={styles.faqDescription}>
          <Textfield
            type="h5"
            style={{ margin: "20px 0px 20px 0px", fontWeight: "bolder" }}
          >
            FAQ
          </Textfield>
          <Textfield
            type="h1"
            style={{ marginBlock: "20px", fontWeight: "bolder" }}
          >
            Quick
            <br /> Answers To
            <br /> Questions You
            <br /> May Have
          </Textfield>
          <Textfield
            type="h6"
            style={{
              marginBlock: "20px",
            }}
          >
            You can find the list of answers to all the questions related to
            Digident Global Academy Courses.
          </Textfield>
          <Link smooth to="#contactus">
            <Button type="outlinedBlack">
              <img src={faqBtn} alt="faqBtn" className="button-icon" />
              Contact
            </Button>
          </Link>
        </div>
        <div className={styles.faqs}>
          <FaqAccordian
            question="Why DGA came into existence?"
            answer="DGA came into existence to address the challenges faced by dental professionals in keeping up with advancements in digital dentistry. Traditional training options were either too expensive or lacked practical, immersive learning experiences. Additionally, dentists were highly dependent on their dental assistants who often struggled to stay updated with the latest technologies. DGA's VR-powered platform provides affordable, convenient, and immersive training for dentists and their staff, ultimately allowing dentists to focus on providing the best possible care to their patients. "
          />
          <FaqAccordian
            question="What kind of Courses does DGA provide? "
            answer="As of now, DGA provides courses for Intra-oral Scanning, Dental Milling, 3D Printing, Dentures' CAD Designing, Sterilization, Emergency Management, X-ray/CBCT management, and Implant Planning. The courses are designed keeping the guidelines provided by FDA and CE."
          />
          <FaqAccordian
            question="Who can take these courses?"
            answer="Dental Assistants who want to upgrade their skills in these new dental technologies like 3D Printing, In-Office milling, Intra-oral Scanning, CAD Designing etc. 
Fresher Dentists or Dentists' in their early days of practice, who want to enhance their skills beyond the core clinicals. 
Dental Colleges who want to make their students future ready and make them easily employable. "
          />
          <FaqAccordian
            question="What certification is provided by DGA?"
            answer="After completion of each learning module, DGA provides the completion certificates to their users. ."
          />
        </div>
      </section>
      <section className={styles.sec6}>
        <Textfield
          colorType="white"
          type="h1"
          style={{
            marginBlock: "20px",
            fontWeight: "bolder",
            textAlign: "left",
          }}
        >
          Always Here for Your <br />
          Dental Education
        </Textfield>
      </section>
      <section className={styles.blogsSec}>
        <div className={styles.divContainer}>
          <FromTheBlogsWrapper
            blogs={[
              {
                imgSrc: blogImg,
                headline:
                  "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
                readMoreLink: "",
              },
              {
                imgSrc: blogImg,
                headline:
                  "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
                readMoreLink: "",
              },
              {
                imgSrc: blogImg,
                headline:
                  "Oral Cancer Awareness: Signs,Symptoms, and Prevention",
                readMoreLink: "",
              },
            ]}
          />
        </div>
      </section>
    </main>
  );
};

export default About;
