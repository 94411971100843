import React from "react";
import styles from "./specialized.module.css";
import Textfield from "../../components/Textfield";
import Button from "../../components/Buttons";
import CardWrapper from "../../components/CardWrapper";
import ellipse from "../../assets/course/ellipse.svg";
import toothImg from "../../assets/course/tooth.svg";
import professorImg from "../../assets/course/Professor.svg";
import logo from "../../assets/logolight.png";
import InstructorSlider from "../InstructorSlider";
import SepcializeCarousel from "../SpecializeCrousel";

const CourseSpecialized = () => {
  const [display, setDisplay] = React.useState(true);

  const isMobile = window.innerWidth <= 768;

  // Toggling between doctors with some delay for the animation effect
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplay((prevState) => !prevState);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  return (
    <main>
      <Textfield
        type="h1"
        colorType="black"
        style={{
          marginBlock: "20px",
          textAlign: "center",
          fontWeight: 900,
        }}
      >
        Meet The Expert Educator
      </Textfield>
      <div className={styles.coursespec}>
        <CardWrapper
          additionalStyles={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginLeft: isMobile ? 0 : 80,
            width: isMobile ? "90%" : "60%",
          }}
        >
          <div>
            <div className={styles.imgDiv}>
              <img src={logo} alt="logo" />
            </div>
            <Textfield
              type="h6"
              style={{
                fontWeight: "500",
                marginTop: "10px",
                marginBotom: "30px",
                letterSpacing: "3px",
                color: "#FFFFFF99",
              }}
            >
              PRIORITISE YOUR DENTAL HEALTH
            </Textfield>
            <Textfield
              colorType="white"
              type="h2"
              style={{ marginBlock: "20px" }}
            >
              Our experienced and
              <br /> compassionate office team <br /> strives to provide a
              positive,
              <br /> stress-free experience at <br /> every visit.
            </Textfield>
          </div>
          <div className={styles.cardFooter}>
            {/* <Textfield colorType="white" type="h2">
              Call Us:
            </Textfield> */}
            <Button type="oulinedBlurBg" style={{ borderRadius: "20px" }}>
              +91 -8880003344
            </Button>
          </div>
        </CardWrapper>
        {/* <div className={`${styles.doctor} ${display ? styles.show : styles.hide}`}>
          <div className={styles.detailsDiv}>
            <Textfield
              colorType="white"
              type="h2"
              style={{ marginTop: "20px", marginBottom: '10px', textDecoration: 'underline white solid 1px', textUnderlineOffset: '5px' }}
            >
              Jeffrey Hoos 
            </Textfield>
            <div className={styles.infoGrid}>
              <span>
                <img src={toothImg} alt="tooth" />
                <Textfield
                  colorType="white"
                  type="h6"
                >
                  8 years Exp.
                </Textfield>
              </span>
              <span>
                <img src={toothImg} alt="tooth" />
                <Textfield
                  colorType="white"
                  type="h6"
                >
                  8 years Exp.
                </Textfield>
              </span>
              <span>
                <img src={toothImg} alt="tooth" />
                <Textfield
                  colorType="white"
                  type="h6"
                >
                  8 years Exp.
                </Textfield>
              </span>
              <span>
                <img src={toothImg} alt="tooth" />
                <Textfield
                  colorType="white"
                  type="h6"
                >
                  8 years Exp.
                </Textfield>
              </span>
            </div>
          </div>
          <img src={professorImg} alt="professorImg" className={styles.docImg} />
        </div>

       

        <div className={`${styles.doctor} ${!display ? styles.show : styles.hide}`}>
          <div className={styles.detailsDiv}>
            <Textfield
              colorType="white"
              type="h2"
              style={{ marginTop: "20px", marginBottom: '10px', textDecoration: 'underline white solid 1px', textUnderlineOffset: '5px' }}
            >
              Ryan Palatino 
            </Textfield>
            <div className={styles.infoGrid}>
              <span>
                <img src={toothImg} alt="tooth" />
                <Textfield
                  colorType="white"
                  type="h6"
                >
                  8 years Exp.
                </Textfield>
              </span>
              <span>
                <img src={toothImg} alt="tooth" />
                <Textfield
                  colorType="white"
                  type="h6"
                >
                  8 years Exp.
                </Textfield>
              </span>
              <span>
                <img src={toothImg} alt="tooth" />
                <Textfield
                  colorType="white"
                  type="h6"
                >
                  8 years Exp.
                </Textfield>
              </span>
              <span>
                <img src={toothImg} alt="tooth" />
                <Textfield
                  colorType="white"
                  type="h6"
                >
                  8 years Exp.
                </Textfield>
              </span>
            </div>
          </div>
          <img src={professorImg} alt="professorImg" className={styles.docImg} />
        </div> */}
        <SepcializeCarousel />
      </div>
    </main>
  );
};

export default CourseSpecialized;
