import { useEffect, useState } from "react";
import Tabs from "../../molecules/Tabs";
import Textfield from "../Textfield";
import styles from "./coursesOverviewStyles.module.css";
import CoursesCard from "./CoursesCard";
import FeaturesBox from "../Contact/FeaturesBox";
import useWindowDimensions from "../../hooks/useWindowsDimention";

import courseCardImg from "../../assets/coursesOverviewCardImg.svg";
import invisibleBraces from "../../assets/course/About this professional certificate course.png";
import Teeth3d from "../../assets/teeth3d.svg";
import CourseHowItWorks from "../../molecules/CourseHowItWorks/CourseHowItWorks";
// Dental Course image
import IntraOralImg from "../../assets/course/Clinic Course/oral.png"
import ThreeDPrinting from "../../assets/course/Clinic Course/print.png"
import MilingCourse from "../../assets/course/Clinic Course/Milling.png"
import XrayCourse from "../../assets/course/Clinic Course/X_ray.png"
import SterlizationCourse from "../../assets/course/Clinic Course/Sterilization.png"

// Lab Course
import Denture from  "../../assets/course/LabCourse/Dentures.png"
import ThreeDCourse from "../../assets/course/LabCourse/TDPrinting.png"
import ImplantCourse from "../../assets/course/LabCourse/Implant_Planning_Surgical_Guide.png"
import RPD from "../../assets/course/LabCourse/RPD.png"
import { useLocation } from 'react-router-dom';
const courseDataClinic = [
  {
    imgSrc: IntraOralImg,
    courseName: "Intra Oral Scanning Course",
    description:
      "Master the art of intraoral scanning with comprehensive training on IOS technology, digital workflows, and troubleshooting techniques for optimal patient care and treatment outcomes.",
    keyPoints: [
      "Setting up of IOS Scanner",
      "Retraction & Isolation Techniques",
      "Retraction & Isolation Techniques",
    ],
    showCommingSoon: false
  },
  {
    imgSrc: ThreeDPrinting,
    courseName: "3D Printing Course",
    description:
      "Master the fundamentals of 3D printing, optimize machine efficiency, and create intricate components with this comprehensive course.",
    keyPoints: [
      "Introduction to 3D Printing",
      "Nesting",
      "Machine Components and Operations",
    ],
    showCommingSoon: true,
  },
  {
    imgSrc: MilingCourse,
    courseName: "Milling Course",
    description:
      "Master essential skills in software training, file management, CAM software, and milling techniques for a successful career in digital dentistry.",
    keyPoints: [
      "Software Training",
      "File Management",
      "CAM Software",
    ],
    showCommingSoon: true,
  },
  {
    imgSrc: XrayCourse,
    courseName: "X-Ray Imaging Course",
    description:
      "Master the art and science of X-Ray imaging, from fundamental principles to advanced equipment operation, with a focus on safe practices.",
    keyPoints: [
      "Techniques & Principle",
      "Equipment Operations",
      "Radiation Safety & Guidelines",
    ],
    showCommingSoon: true,
  },

  {
    imgSrc: SterlizationCourse,
    courseName: "Sterilization Course ",
    description:
      "Master digital dentistry's sterilization essentials: instrument processing, protective gear, and diverse methods for safe and efficient practice.",
    keyPoints: [
      "Different Methods ",
      "Instrument Processing ",
      "Protective Equipment's",
    ],
    showCommingSoon: true,
  },
];

const courseDataLab = [
  {
    imgSrc: Denture,
    courseName: "Digital Dentures Course ",
    description:
      "Transform your digital denture practice with comprehensive training in advanced techniques and technology. ",
    keyPoints: [
      "Denture Mechanics ",
      "Clinical & Lab Workflow ",
      "Occlusion Types ",
    ],
    showCommingSoon: true,
  },
  {
    imgSrc: ThreeDCourse,
    courseName: "Advanced 3D Printing Course",
    description:
      "Deep dive into 3D printing technology for lab environments, optimizing machine efficiency and creating intricate components.",
    keyPoints: [
      "Advanced 3D Printing Techniques",
      "Material Science",
      "Machine Maintenance",
    ],
    showCommingSoon: true,
  },
  {
    imgSrc: ImplantCourse,
    courseName: "Implant Planning & Surgical Guide Designing Course ",
    description:
      "Master digital implant precision from surgical planning to execution. ",
    keyPoints: [
      "Digital Imaging & 3D Planning ",
      "Implant Planning Techniques ",
      "Designing Surgical Guides ",
    ],
    showCommingSoon: true,
  },
  {
    imgSrc: RPD,
    courseName: "RPD’s ",
    description:
      "Master the concept of RPD’s with our comprehensive guide course. ",
    keyPoints: [
      "Fundamentals of RPD’s",
      "Digital Design Techniques ",
      "Clinical Adjustments & Delivery ",
    ],
    showCommingSoon: true,
  },
];


const CoursesOverviewComp = () => {
  const location = useLocation();
  const [course, setCourse] = useState('');
  const [courseHeader, setCourseHeader] = useState('');


  const [currentTabIdx, setCurrentTabIdx] = useState(1);
  const[coursesToDisplay, setCoursesToDisplay] = useState([]);
  const { width } = useWindowDimensions();
  
  useEffect(() => {
    const coursesToDisplay = currentTabIdx === 1 ? courseDataClinic : courseDataLab;
    setCoursesToDisplay(coursesToDisplay);


      if (currentTabIdx === 1) {
        setCurrentTabIdx(1);
        setCourseHeader('Dental Clinic Courses');
      }
      else if (currentTabIdx === 2) {
        setCurrentTabIdx(2);
        setCourseHeader('Dental Lab Courses');
      }
    
  }, [currentTabIdx]);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const courseParam = queryParams.get('course'); // Fetch the value of "course"
    // console.log(courseParam);
    if (courseParam) {
      setCourse(courseParam);
      if (courseParam === 'dental') {
        setCurrentTabIdx(1);
        setCourseHeader('Dental Clinic Courses');
      }
      else if (courseParam === 'lab') {
        setCurrentTabIdx(2);
        setCourseHeader('Dental Lab Courses');
      }
    }
  }, [location.search]);

  return (
    <main>
      <section className={styles.formSec}>
        <Textfield
          type="h1"
          colorType="white"
          style={{
            marginInline: "auto",
            textAlign: "center",
            fontSize: "48px",
            textDecoration: "underline white solid 5px",
            textUnderlineOffset: "10px",
            lineHeight: "1.5",
          }}
        >
          {courseHeader}
        </Textfield>
      </section>
      <Tabs
        tabsArr={["Dental Clinic Courses", "Dental Lab Courses"]}
        currentTabIdx={currentTabIdx}
        setCurrentTabIdx={setCurrentTabIdx}
        additionalStyles={{
          position: "relative",
          marginInline: "auto",
          top: width > 768 ? "-80px" : "-35px",
        }}

      />
      <section className={styles.coursesCardContainer}>
      {coursesToDisplay.map((course, index) => (
        <CoursesCard
          key={index}
          imgSrc={course.imgSrc}
          courseName={course.courseName}
          description={course.description}
          keyPoints={course.keyPoints}
          showCommingSoon={course.showCommingSoon}
        />
      ))}
      </section>
      <section className={styles.certificateSec}>
        <Textfield
          type="h1"
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            textAlign: "center",
            ...(width < 600 && { fontSize: "24px" }),
          }}
        >
          About The Courses 
        </Textfield>
        <div className={styles.certificateFeatures}>
          <div className={styles.featuresBoxContainer}>
            <FeaturesBox
              imgSrc={Teeth3d}
              heading="DGA Certificate of Completion"
              description="You get a certificate upon completion of the course."
            />
            <FeaturesBox
              imgSrc={Teeth3d}
              heading="Virtual Hands-On Experience"
              description="You get hands-on experience on VR devices"
            />
            <FeaturesBox
              imgSrc={Teeth3d}
              heading="Reputed Educators on the Panel"
              description="Best and Experienced Educators will teach the courses. "
            />
          </div>
          <img
            src={invisibleBraces}
            alt="invisibleBraces"
            className={styles.contImg}
          />
        </div>
      <CourseHowItWorks />

      </section>
    </main>
  );
};

export default CoursesOverviewComp;
